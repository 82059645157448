import type { SVGProps } from 'react';
import { env } from '~/config/env';

export function Logo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="122"
      height="24"
      viewBox="0 0 122 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{env.VITE_APP_NAME}</title>
      <g id="logo">
        <path
          id="ED"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.2376 14.5251H50.0526C50.6731 14.5251 51.0608 14.0319 51.0608 13.4348C51.0608 12.7859 50.6989 12.3186 50.0526 12.3186H41.2376V6.94512C41.2376 6.3221 41.4961 6.03656 42.1165 6.03656H51.2159C51.888 6.03656 52.2499 5.54334 52.2499 4.89437C52.2499 4.2454 51.888 3.77814 51.2159 3.77814H41.4444C39.609 3.77814 38.8076 4.58286 38.8076 6.58169V21.2225C38.8076 23.1954 39.609 24.0001 41.4444 24.0001H51.4486C52.1207 24.0001 52.4826 23.4809 52.4826 22.8579C52.4826 22.2089 52.1207 21.7417 51.4486 21.7417H42.1941C41.6253 21.7417 41.2376 21.508 41.2376 20.911V14.5251ZM55.5329 21.2225C55.5329 23.1953 56.3343 24.0001 58.1697 24.0001H62.7711C68.4324 24.0001 71.8446 20.1322 71.8446 13.6684C71.8446 7.41236 68.4841 3.77812 62.7711 3.77812H58.1697C56.3343 3.77812 55.5329 4.58285 55.5329 6.58168V21.2225ZM57.9889 6.86725C57.9889 6.32211 58.3508 6.01061 58.842 6.01061H62.7196C66.9849 6.01061 69.2856 8.73628 69.2856 13.6944C69.2856 18.7564 66.8298 21.7417 62.642 21.7417H58.8161C58.2991 21.7417 57.9889 21.4561 57.9889 20.8591V6.86725Z"
          fill="currentColor"
        />
        <path
          id="IZZY"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M75.0293 23.8183H75.5906V3.69498H75.0293V23.8183ZM77.1343 23.2546L91.1394 4.45592V3.69495H77.3588V4.25863H90.5781L76.5729 23.0573V23.8182H91.3639V23.2546H77.1343ZM91.1673 23.2546L105.172 4.45595V3.69498H91.3919V4.25866H104.611L90.606 23.0573V23.8183H105.397V23.2546H91.1673ZM112.723 14.7431L105.032 3.69498H104.331L112.442 15.3349V23.8183H113.003V15.3349L121.114 3.69498H120.441L112.723 14.7431Z"
          fill="currentColor"
        />
        <path
          id="Path 18"
          d="M14.5024 14.4551C14.9214 14.4551 15.2922 14.1826 15.4189 13.7815L19.3764 1.2577L17.5309 1.21536L23.3595 23.0781C23.6071 24.0071 24.9073 24.0413 25.203 23.1266L30.7809 5.87225L28.9723 5.92989L32.0845 13.8149C32.2298 14.183 32.5842 14.4248 32.9785 14.4248H41.2447C41.7758 14.4248 42.2063 13.9924 42.2063 13.4591C42.2063 12.9258 41.7758 12.4935 41.2447 12.4935H32.9785L33.8724 13.1033L30.7602 5.2183C30.4282 4.37713 29.2299 4.41533 28.9516 5.27594L23.3737 22.5303L25.2173 22.5787L19.3887 0.715924C19.1402 -0.216246 17.8339 -0.246217 17.5432 0.673583L13.5858 13.1974L14.5024 12.5238H0.961604C0.430525 12.5238 0 12.9562 0 13.4895C0 14.0228 0.430525 14.4551 0.961604 14.4551H14.5024Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
